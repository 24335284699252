$(document).on('turbolinks:load', () => {
    const mod = document.getElementById('thredded-choose-display-name');
    if (mod) {
        const validated = $('#name-is-validated');
        $(mod).modal({backdrop: 'static', keyboard: false});


        $('#user_name').on('keyup', evt => {
            const val = evt.target.value;
            if (val.length < 3) {
                return;
            }

            $.getJSON('/account/check_name', {name: val}, ({ok}) => {
                if (ok) {
                    validated.addClass('icon-checkmark').addClass('text-success');
                    validated.removeClass('icon-cross').removeClass('text-danger');
                } else {
                    validated.removeClass('icon-checkmark').removeClass('text-success');
                    validated.addClass('icon-cross').addClass('text-danger');
                }
            });
        });

    }
});
