import './media';
import './cart';

let form = null;

Spree.ready($ => {
    $('[data-toggle="tooltip"]').tooltip({container: 'body'});
    Spree.updateVariantPrice = variant => {
        let variantPrice = variant.data('price');
        if (variantPrice) {
            $('.price.selling').text(variantPrice);
        }
    };

    form = $('#products_filter');
    const cb = form.find('[type="checkbox"]');
    const rb = form.find('[type="radio"]');
    const sorting = $('#product_sort a');

    form.off('submit', triggerForm);
    cb.off('change', triggerFilter);
    rb.off('change', triggerFilter);
    sorting.off('click', triggerOrder);

    form.on('submit', triggerForm);
    cb.on('change', triggerFilter);
    rb.on('change', triggerFilter);
    sorting.on('click', triggerOrder);

});

function triggerOrder(evt) {
    evt.preventDefault();
    if (form) {
        const link = $(evt.target);
        const target = link.attr('data-target');
        const sort = form.children('#sort');

        let sorted = sort.val().split('_by_');
        if (link.hasClass('sort-direction')) {
            sorted[0] = target;
        } else {
            sorted[1] = target;
        }
        sort.val(sorted.join('_by_'));
        form.submit();
    }
}

function triggerForm(evt) {
    if (Turbolinks) {
        evt.preventDefault();
        const req = $(evt.target).serialize();
        Turbolinks.visit('?' + req);
    }
}

function triggerFilter(evt) {
    if (form) {
        form.submit();
    }
}