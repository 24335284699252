import Spree from 'spree';
import source from './search_results.handlebars';
import Handlebars from 'handlebars/dist/handlebars';
import addToCartIcon from 'cart-add.svg';

var searcherSetup = false;

Spree.ready(function ($) {
    const handleSuggestion = (query, item) => {
        return source({...item, addToCartIcon});
    };

    console.log('typeahead', window.Typeahead['#keywords']);

    if (!searcherSetup) {
        $('#keywords').typeahead({
            cancelButton: false,
            debug: true,
            display: 'products',
            dynamic: true,
            maxItem: 0,
            resultContainer: '#search-dropdown .typeahead__result',
            group: false,
            template: handleSuggestion,
            templateValue: "{{name}}",
            href: ({url}) => url,
            source: {
                products: {
                    filter: false,
                    ajax: {
                        url: '/products.json',
                        data: {keywords: '{{query}}'},
                        path: 'data',
                        callback: {
                            done(data, textStatus, jqXHR) {
                                console.log(data);
                                return data;
                            },
                        },
                    },
                },
            },
            callback: {
                onResult(node, query, result, resultCount, resultCountPerGroup) {
                    console.log(node, query, result);
                },
                onSubmit(node, form, item, event) {
                    form.submit();
                },
                onClickAfter() {
                    toggleSearchBar();
                }
            },
        });
        searcherSetup = true;
    }
});

function toggleSearchBar() {
    var searchDropdown = document.getElementById('search-dropdown')

    if (searchDropdown.classList.contains('shown')) {
        document.querySelector('.header-spree').classList.remove('above-overlay')
        document.getElementById('overlay').classList.remove('shown')
        searchDropdown.classList.remove('shown')
    } else {
        document.querySelector('.header-spree').classList.add('above-overlay')
        document.getElementById('overlay').classList.add('shown')
        searchDropdown.classList.add('shown')
        document.querySelector('#search-dropdown input').focus()
    }
}
