var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span class=\"product-search__discount\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"discount") : stack1), depth0))
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"product-search__price-original\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"originalPrice") : depth0)) != null ? lookupProperty(stack1,"price") : stack1), depth0))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"product-search__container\">\n  <div class=\"product-search__image-container position-relative\">\n    <img class=\"product-search__image img-thumbnail\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data,"loc":{"start":{"line":3,"column":58},"end":{"line":3,"column":67}}}) : helper)))
    + "\" alt=\"\">\n  </div>\n  <div class=\"product-search__metadata\">\n    <div class=\"product-search__title\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"originalPrice") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":9,"column":13}}})) != null ? stack1 : "")
    + "      "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":10,"column":14}}}) : helper)))
    + "\n    </div>\n    <div class=\"product-search__description\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":12,"column":45},"end":{"line":12,"column":62}}}) : helper))) != null ? stack1 : "")
    + "</div>\n  </div>\n  <div class=\"product-search__price\">\n    <div class=\"product-search__price-actual\">\n      "
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? lookupProperty(stack1,"price") : stack1), depth0))
    + "\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"originalPrice") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"product-component-add-to-cart-button\">\n    <button class=\"disabled add-to-cart-button btn btn-success btn-sm\" data-variant=\"<%= product.default_variant_id %>\">\n      <svg href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"addToCartIcon") || (depth0 != null ? lookupProperty(depth0,"addToCartIcon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"addToCartIcon","hash":{},"data":data,"loc":{"start":{"line":24,"column":17},"end":{"line":24,"column":34}}}) : helper)))
    + "\" height=\"16\" width=\"16\"></svg>\n    </button>\n  </div>\n</div>\n";
},"useData":true});