import Spree from 'spree';
import SpreeAPI from 'spree_api';

Spree.ready($ => {
    let forms = document.getElementsByClassName('add-to-cart__form');
    let len = forms.length;
    for (var i = 0; i < len; i++) {
        let form = forms[i];
        let btn = form.querySelector('.add-to-cart__add-to-cart-button');
        if (btn && btn.className.indexOf('product__owned') === -1) {
            btn.removeAttribute('disabled');
        }
        form.addEventListener('submit', addItemToCart);
    }
});

function addItemToCart(evt) {
    evt.preventDefault();

    let btn = this.querySelector('.add-to-cart__add-to-cart-button');
    if (btn) {
        btn.setAttribute('disabled', 'disabled');
        let variantId = parseInt(btn.getAttribute('data-variant'), 10);

        Spree.ensureCart(
            function () {
                SpreeAPI.Storefront.addToCart(
                    variantId,
                    1,
                    {}, // options hash - you can pass additional parameters here, your backend
                    // needs to be aware of those, see API docs:
                    // https://github.com/spree/spree/blob/master/api/docs/v2/storefront/index.yaml#L42
                    function () {
                        Spree.notify.success('The product was added');
                        Spree.fetchCart();
                    },
                    function (error) {
                        Spree.notify.warning(error, {labels: {attention: 'Failed to add the product'}});
                    }, // failure callback for 422 and 50x errors
                );
            },
        );
    }
}