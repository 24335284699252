import $ from 'jquery';
import Clickout from 'clickout';

$(document).on('turbolinks:load', () => {
    const el = $('#subscribe-to-newsletter')
        .popover('dispose')
        .popover({
            placement: 'top',
            content: $('#newsletter-form'),
            html: true,
            title: 'Subscribe to our newsletter',
        })
        .off('click')
        .on('click', function (evt) {
            evt.preventDefault();
            const id = this.attributes.getNamedItem('aria-describedby');
            if (!id) {
                $(this).popover('show');
            }
        }).on('shown.bs.popover', function (e) {
            const id = this.attributes.getNamedItem('aria-describedby');
            const el = document.querySelector('#' + id.value);

            Clickout.bind(el, (evt) => {
                if ($(this).has(evt.target).length) {
                    return;
                }
                $(this).popover('hide');
            });
        }).on('hide.bs.popover', function () {
            const id = this.attributes.getNamedItem('aria-describedby');
            if (!id) return;

            const el = document.querySelector('#' + id.value);
            Clickout.destroy(el);
        });
});