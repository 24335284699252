$(document).on('turbolinks:load', () => {
    $(function () {
        const colls = $('#product-collection [data-toggle="popover"]');
        colls.on('click', e => e.preventDefault());

        colls.popover({
            content: function () {
                const attr = $(this).attr('data-element');
                return $(attr).clone();
            },
            html: true,
            placement: 'bottom',
            container: '#product-collection',
            offset: (d, o) => {
                const {popper, ...rest} = d;
                popper.left = 0;
                popper.right = 0;
                return {popper, ...rest};
            },
        }).on('show.bs.popover', function (evt) {
            colls.filter((i, e) => e !== this).each((i, e) => $(e).popover('hide'));
        });
    });
});
