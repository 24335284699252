import $ from 'jquery';

$(document).on('turbolinks:load', () => {
    const target = $('#media-viewer-target-0');
    if(target.length) {
        const list = target.data('list');

        var myViewer_0 = new ax5.ui.mediaViewer({
            target: target,
            loading: {
                icon: '<i class="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom" aria-hidden="true"></i>',
                text: '<div>Now Loading</div>',
            },
            media: {
                prevHandle: '<i class="icon-arrow-left"></i>',
                nextHandle: '<i class="icon-arrow-right"></i>',
                width: 36, height: 36,
                poster: '<i class="fa fa-youtube-play" style="line-height: 32px;font-size: 20px;"></i>',
                list: list,
            },
        });
    }
});