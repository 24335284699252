/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'normalize.css';
import 'popper.js';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';
import 'home';
import 'products';
import 'account';
import 'thredded';
import 'shared/newsletter'

import 'shared/search.js';
import Awn from 'awesome-notifications';
import 'awesome-notifications/src/styles/style.scss';

Spree.notify = new Awn({
    durations: {
        global: 2500,
    },
    icons: {
        prefix: '<i class="fa ',
        suffix: '"></i>',
        tip: 'icon-question',
        info: 'icon-info',
        success: 'icon-checkmark',
        warning: 'icon-warning',
        alert: 'icon-notification2',
    },
});
